import { useState } from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ButtonGroup, IconButton, Typography } from "@mui/material";
import {
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";

import S3Image from "../../../../../../../../common/components/media/S3Image";
import { useAuthenticatedUser } from "../../../../../../../../common/hooks/useAuthenticatedUser";
import { ADMIN_ACCESS } from "../PTZoneListTableContainer";

export const usePTGetZonesByDeviceTableColumns = (): {
  columns: GridColDef[];
  rowModesModel: GridRowModesModel;
  setRowModesModelChange: (newRowModesModel: GridRowModesModel) => void;
} => {
  const { role } = useAuthenticatedUser();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const setRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel);
  };

  const resetEditMode = (id: GridRowId, ignoreModifications: boolean): void => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications },
    });
  };

  const handleCancelClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, true);
    };

  const handleSaveClick =
    (id: GridRowId): (() => void) =>
    (): void => {
      resetEditMode(id, false);
    };

  const hasAdminAccess = ADMIN_ACCESS.includes(role);

  const nonAdminColumns: GridColDef[] = [
    {
      field: "keyFrame",
      headerName: "Snapshot",
      flex: 1,
      minWidth: 140,
      maxWidth: 220,
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params): JSX.Element | null => {
        const keyFrame = params?.row?.signedUrlKeyFrame;

        return <S3Image s3Key={keyFrame} width={180} />;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      hideable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.name}</Typography>
        ) : null,
    },
  ];

  if (hasAdminAccess) {
    return {
      columns: [
        ...nonAdminColumns,
        {
          field: "action",
          headerName: "",
          disableColumnMenu: true,
          hideable: false,
          sortable: false,
          type: "actions",
          renderCell: (params): JSX.Element => {
            const activeRow = rowModesModel[params.id]?.mode;

            return (
              <ButtonGroup variant="text" color="secondary">
                {activeRow && (
                  <>
                    <IconButton
                      aria-label="cancel"
                      onClick={handleCancelClick(params.id)}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                    <IconButton
                      aria-label="save"
                      onClick={handleSaveClick(params.id)}
                    >
                      <CheckRoundedIcon />
                    </IconButton>
                  </>
                )}
              </ButtonGroup>
            );
          },
        },
      ],
      rowModesModel,
      setRowModesModelChange,
    };
  }

  return { columns: nonAdminColumns, rowModesModel, setRowModesModelChange };
};
