import { FC, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import HLSStreamPlayer from "../../../pages/data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import PTControllerButton from "../stream/PTControllerButton";
import { ViewModeEnum } from "./LiveViewContainer";
import ExtendButton from "./MaximizeButton";

interface LiveViewExpandedDialogProps {
  s3Key: string;
  viewMode: ViewModeEnum;
  expanded: boolean;
  changeViewMode?: () => void;
  changeExpandedMode: () => void;
  openPtController?: () => void;
}

const LiveViewExpandedDialog: FC<LiveViewExpandedDialogProps> = ({
  s3Key,
  viewMode,
  expanded,
  changeViewMode,
  changeExpandedMode,
  openPtController,
}) => {
  const [videoLoading, setVideoLoading] = useState(false);

  const getLiveViewDialogPContentStyles = () => {
    if (viewMode === ViewModeEnum.LIVE) {
      return {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: 0,
      };
    }

    return {};
  };

  const liveViewDialogContentStyles = getLiveViewDialogPContentStyles();

  return (
    <Dialog
      id="LiveViewExpandedDialog"
      PaperProps={{
        sx: {
          margin: 0,
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          borderRadius: 0,
        },
      }}
      fullWidth
      open={expanded}
      onClose={changeExpandedMode}
    >
      <Box sx={{ position: "absolute", zIndex: 3, right: 8, top: 8 }}>
        <ExtendButton
          title="Minimize"
          changeExpandedMode={changeExpandedMode}
        />
      </Box>

      <DialogContent
        sx={{
          padding: 0,
          margin: "0 auto",
          overflowX: "auto",
          width: "max-content",
          ...liveViewDialogContentStyles,
        }}
      >
        {(viewMode === ViewModeEnum.KEYFRAME ||
          viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS) && (
          <CardMedia
            sx={{
              width: "fit-content",
              height: "100vh",
            }}
            src={s3Key}
            component="img"
          />
        )}

        {viewMode === ViewModeEnum.LIVE && (
          <HLSStreamPlayer
            setDialogStyles
            videoLoading={videoLoading}
            setVideoLoading={setVideoLoading}
          />
        )}

        {changeViewMode && (
          <Box
            sx={{
              position: "fixed",
              left: "50%",
              bottom: "20px",
              transform: "translate(-50%, -50%)",
              margin: "0 auto",
              display: "flex",
              gap: "1em",
            }}
          >
            <StyledLoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={changeViewMode}
            >
              {viewMode !== ViewModeEnum.LIVE ? "Activate" : "Stop"} live view
            </StyledLoadingButton>
            {openPtController && (
              <PTControllerButton onPtOpen={openPtController} />
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LiveViewExpandedDialog;
