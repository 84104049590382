import { memo, useEffect, useState } from "react";

import CardMedia from "@mui/material/CardMedia";
import { SxProps, Theme } from "@mui/material/styles";

import { IS3MediaFile } from "./IS3MediaFileProps";
import KeyFrameMessage from "./NoKeyFrameMessage";

interface S3ImageProps extends IS3MediaFile {
  isKeyframeLoaded?: boolean;
  width?: number;
  deviceStatus?: boolean;
  height?: number;
  sx?: SxProps<Theme>;
  setKeyframeLoaded?: (value: boolean) => void;
  onClick?: () => void;
}

const S3ImageComponent = ({
  s3Key,
  width,
  height,
  sx = [],
  isKeyframeLoaded,
  deviceStatus,
  setKeyframeLoaded,
  onClick,
}: S3ImageProps): JSX.Element => {
  const [imageUrl, setImageUrl] = useState("");
  const [doesFileExist, setDoesFileExist] = useState(false);

  const defaultSx = {
    objectFit: "fill",
    aspectRatio: "auto",
  };

  useEffect((): void => {
    getImage();
  }, [s3Key]);

  const getImage = async (): Promise<void> => {
    if (!s3Key) {
      setImageUrl("");

      setDoesFileExist(false);

      if (setKeyframeLoaded) {
        setKeyframeLoaded(false);
      }

      return;
    }

    setImageUrl(s3Key);

    setDoesFileExist(true);

    if (setKeyframeLoaded) {
      // checkIfFileExist has finished and therefore loading is complete
      setKeyframeLoaded(true);
    }
  };

  /**
   * If the image fails to load, show 'Please wait for the keyframe to retrieve' message
   */
  const handleImageError = async () => {
    setImageUrl("");

    setDoesFileExist(false);

    if (setKeyframeLoaded) {
      setKeyframeLoaded(false);
    }
  };

  return (
    <>
      {!isKeyframeLoaded && (
        <>
          {deviceStatus === false && !imageUrl ? (
            <KeyFrameMessage message="The device is offline. It can take some time" />
          ) : (
            (!doesFileExist || (!imageUrl && deviceStatus === undefined)) && (
              <KeyFrameMessage message="Please wait for the keyframe to retrieve" />
            )
          )}
        </>
      )}

      {imageUrl && doesFileExist && (
        <CardMedia
          onClick={onClick}
          component="img"
          src={imageUrl}
          width={width}
          height={height}
          sx={[defaultSx, ...(Array.isArray(sx) ? sx : [sx])]}
          onError={handleImageError}
        />
      )}
    </>
  );
};

const S3Image = memo(
  S3ImageComponent,
  (prevProps, nextProps): boolean => prevProps.s3Key === nextProps.s3Key
);

export default S3Image;
