import { Button, Typography } from "@mui/material";

import { usePublishNode } from "../../../pages/devices/hooks/usePublishNode";
import {
  openPTConctollerVariable,
  useSelectedStreamObject,
} from "../../variables/selectedStreamObject";

interface PTControllerButtonProps {
  onPtOpen?: () => void;
}

const PTControllerButton = ({
  onPtOpen,
}: PTControllerButtonProps): JSX.Element => {
  const selectedStreamObject = useSelectedStreamObject();
  const { publishNode } = usePublishNode();

  const handleOpenPtControlsOnClick = (): void => {
    if (!selectedStreamObject) {
      console.error("No stream data");

      return;
    }

    if (onPtOpen) {
      onPtOpen();
    }

    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "START_PT_CONTROL",
        device_name: selectedStreamObject.extractedDeviceName,
      }),
      nodeId: selectedStreamObject.nodeId,
    });

    openPTConctollerVariable(true);
  };

  return (
    <Button
      sx={{
        "&": {
          color: "text.primary",
          borderColor: "text.primary",
          backgroundColor: "otherBackgroundLight.main",
          textTransform: "none",
        },
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "otherBackgroundLight.main",
          textTransform: "none",
        },
      }}
      variant="outlined"
      size="small"
      onClick={handleOpenPtControlsOnClick}
    >
      <Typography variant="buttonMedium" component="span">
        Open PT controller
      </Typography>
    </Button>
  );
};

export default PTControllerButton;
