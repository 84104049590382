import { ReactElement, ReactNode, useMemo } from "react";

import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Breakpoint, styled } from "@mui/material/styles";

import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import StyledDialogTitle from "./StyledDialogTitle";

interface IStyledDialog {
  open: boolean;
  title?: string;
  subTitle?: string;
  onClose: () => void;
  fullScreen?: boolean;
  maxWidth?: Breakpoint | false;
  content?: ReactNode;
  children?: ReactNode;
  showSubmitButton?: boolean;
  disableEscapeKeyDown?: boolean;
  SubmitButtonProps?: {
    loading?: boolean;
    title?: string;
    icon?: ReactElement | JSX.Element;
    onSubmit?: () => void;
  };
}

const DialogWindow = styled(Dialog)(
  ({
    theme,
  }): {
    "& .MuiDialog-paper": {
      padding: string | number;
      borderRadius: string | number;
    };
    "& .MuiDialogContent-root": {
      padding: string | number;
      overflowY: string;
    };
    "& .MuiDialogActions-root": {
      padding: string | number;
    };
  } => ({
    "& .MuiDialog-paper": {
      padding: "48px 64px",
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
      overflowY: "initial",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(3, 0, 0, 0),
    },
  })
);

const StyledDialog = ({
  open,
  onClose,
  title,
  subTitle,
  content,
  children,
  SubmitButtonProps,
  disableEscapeKeyDown,
  fullScreen = false,
  showSubmitButton = true,
  maxWidth = "sm",
}: IStyledDialog): JSX.Element => {
  const loading = SubmitButtonProps?.loading ?? false;
  const icon = SubmitButtonProps?.icon;
  const buttonTitle = SubmitButtonProps?.title ?? "";
  const onSubmit = SubmitButtonProps?.onSubmit;

  const memoizedContent = useMemo(
    (): ReactNode => content ?? children,
    [content, children]
  );

  return (
    <DialogWindow
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
      scroll="body"
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {title && (
        <StyledDialogTitle
          onClose={onClose}
          title={title}
          subTitle={subTitle}
        />
      )}

      <DialogContent
        sx={{
          height: "100%",
        }}
      >
        {memoizedContent}
      </DialogContent>

      {showSubmitButton && (
        <DialogActions>
          <StyledLoadingButton
            fullWidth
            loading={loading}
            loadingPosition="start"
            startIcon={icon ?? <CheckIcon />}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {buttonTitle ?? "Create"}
          </StyledLoadingButton>
        </DialogActions>
      )}
    </DialogWindow>
  );
};

export default StyledDialog;
