export const extractLocationNameFromId = (id: string): string => {
  if (!id) {
    return "";
  }

  const names = id.split("#");
  const valueName = names[names.length - 1];

  return valueName;
};

export const extractDeviceNameFromId = (
  id: string
): { extractedDeviceName: string } => {
  if (!id) {
    return {
      extractedDeviceName: "",
    };
  }

  const extractedDeviceName = id.split("DE#")[1];

  return { extractedDeviceName };
};
