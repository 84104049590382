import { FC, useEffect, useRef } from "react";

import Hls from "hls.js";

import { videoPlayerVariable } from "../../../../../../common/variables/videoPlayer";

interface LiveStreamPlayerProps {
  streamUrl: string;
  setDialogStyles?: boolean;
}

const LiveStreamPlayer: FC<LiveStreamPlayerProps> = ({
  streamUrl,
  setDialogStyles,
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let hls: Hls | null = null;

    if (!videoRef.current) {
      return;
    }

    if (Hls.isSupported()) {
      hls = new Hls({
        liveSyncDuration: 3,
        liveBackBufferLength: 3,
        maxBufferLength: 50,
        maxMaxBufferLength: 60,
        liveDurationInfinity: true,
      });

      hls.loadSource(streamUrl);

      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current
          ?.play()
          .then(() => {
            videoPlayerVariable(true);
          })
          .catch(error => {
            console.error("Error playing video:", error);
          });
      });

      videoRef.current.addEventListener("seeking", () => {
        if (!videoRef.current || !hls) return;

        const liveEdge = hls.liveSyncPosition ?? videoRef.current.duration;

        if (liveEdge !== Infinity && videoRef.current.currentTime < liveEdge) {
          videoRef.current.currentTime = liveEdge;
        }
      });
    } else {
      if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.src = streamUrl;

        videoRef.current.addEventListener("loadedmetadata", () => {
          videoRef.current
            ?.play()
            .then(() => {
              videoPlayerVariable(true);
            })
            .catch(error => {
              console.error("Error playing video:", error);
            });
        });
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [streamUrl]);

  return (
    <>
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{
          width: setDialogStyles ? "fit-content" : "100%",
          maxWidth: "100%",
          height: "100%",
          borderRadius: setDialogStyles ? 0 : "8px",
          objectFit: "fill",
          aspectRatio: "auto",
        }}
      >
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default LiveStreamPlayer;
