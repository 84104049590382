import { FC, memo, useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { Annotation } from "../../../API";
import HLSStreamPlayer from "../../../pages/data-hub/components/liveview/components/cameraView/HLSStreamPlayer";
import PtControllerDialog from "../../../pages/devices/device-details/components/PtControllerDialog";
import { IDeviceTableRow } from "../../../pages/devices/hooks/useDeviceTableRows";
import { useStreamProcesses } from "../../hooks/useStreamProcesses";
import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import ViewKeyframeWithAnnotations from "../canvas/ViewKeyframeWithAnnotations";
import S3Image from "../media/S3Image";
import WarningMessage from "../stream/WarningMessage";
import LiveViewExpandedDialog from "./LiveViewExpandedDialog";
import ExtendButton from "./MaximizeButton";

export enum ViewModeEnum {
  LIVE,
  KEYFRAME,
  KEYFRAME_WITH_ANNOTATIONS,
}

export type ViewMode =
  | ViewModeEnum.LIVE
  | ViewModeEnum.KEYFRAME
  | ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS;

interface KeyframeContainerProps {
  device: IDeviceTableRow;
  s3Key: string;
  showPtButton: boolean;
  viewMode: ViewMode;
  annotationsData?: Annotation[];
  defaultViewMode?: ViewMode;
  isKeyframeLoadedDefaultValue?: boolean;
  setKeyframeLoadedHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentViewMode?: (mode: ViewMode) => void;
}

const LiveViewContainerComponent: FC<KeyframeContainerProps> = ({
  device,
  s3Key,
  showPtButton,
  viewMode,
  isKeyframeLoadedDefaultValue,
  setKeyframeLoadedHandler,
  annotationsData,
  defaultViewMode = ViewModeEnum.KEYFRAME,
  setCurrentViewMode,
}): JSX.Element => {
  const { startStreaming, stopStreaming } = useStreamProcesses();

  const [expanded, setExpanded] = useState(false);
  const [ptOpen, setPtOpen] = useState(false);
  const [isKeyframeLoaded, setKeyframeLoaded] = useState(
    !!isKeyframeLoadedDefaultValue
  );

  const [videoLoading, setVideoLoading] = useState(false);

  const onSetIsKeyFrameLoaded = (newValue: boolean) => {
    setKeyframeLoaded(newValue);

    if (setKeyframeLoadedHandler) {
      setKeyframeLoadedHandler(newValue);
    }
  };

  const showKeyFrameWithAnnotations =
    annotationsData &&
    typeof isKeyframeLoaded === "boolean" &&
    !!setKeyframeLoaded;

  const changeViewMode = (): void => {
    if (setCurrentViewMode) {
      if (viewMode !== ViewModeEnum.LIVE) {
        startStreaming(device);

        setCurrentViewMode(ViewModeEnum.LIVE);

        return;
      }

      stopStreaming(device);

      if (defaultViewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS) {
        setCurrentViewMode(ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS);

        return;
      }

      setCurrentViewMode(ViewModeEnum.KEYFRAME);
    }
  };

  const changeExpandedMode = (): void => {
    setExpanded((prev): boolean => !prev);
  };

  const memoizedOpenPtControllerCallback = useCallback(() => {
    setExpanded(false);

    if (viewMode !== ViewModeEnum.LIVE) {
      startStreaming(device);
    }

    setPtOpen(true);
  }, [viewMode, device]);

  const memoizedClosePtControllerCallback = useCallback(
    (mode?: ViewMode) => {
      if (mode !== ViewModeEnum.LIVE) {
        stopStreaming(device);
      }

      setPtOpen(false);
    },
    [device]
  );

  let maxWidth = device.isOnline && isKeyframeLoaded ? "fit-content" : "100%";

  if (viewMode === ViewModeEnum.LIVE) {
    maxWidth = videoLoading ? "100%" : "fit-content";
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        alignItems: "center",
      }}
    >
      <LiveViewExpandedDialog
        viewMode={viewMode}
        expanded={expanded}
        s3Key={s3Key}
        changeViewMode={changeViewMode}
        changeExpandedMode={changeExpandedMode}
        openPtController={
          showPtButton ? memoizedOpenPtControllerCallback : undefined
        }
      />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth,
          height: "378px",
          borderRadius: "8px",
          border: !isKeyframeLoaded
            ? (theme): string =>
                `1px solid ${theme.palette.otherOutlineBorder.main}`
            : "",
        }}
      >
        {isKeyframeLoaded && (
          <ExtendButton
            title="Maximize"
            changeExpandedMode={changeExpandedMode}
          />
        )}

        {viewMode === ViewModeEnum.KEYFRAME && (
          <S3Image
            sx={{
              width:
                device.isOnline && isKeyframeLoaded ? "fit-content" : "100%",
              height: "378px",
              borderRadius: "8px",
              aspectRatio: "auto",
            }}
            s3Key={s3Key}
            deviceStatus={device.isOnline}
            isKeyframeLoaded={isKeyframeLoaded}
            setKeyframeLoaded={onSetIsKeyFrameLoaded}
          />
        )}

        {viewMode === ViewModeEnum.LIVE && (
          <HLSStreamPlayer
            videoLoading={videoLoading}
            setVideoLoading={setVideoLoading}
          />
        )}

        {viewMode === ViewModeEnum.KEYFRAME_WITH_ANNOTATIONS &&
          showKeyFrameWithAnnotations && (
            <ViewKeyframeWithAnnotations
              annotationsData={annotationsData}
              isKeyframeLoaded={isKeyframeLoaded}
              keyFrame={s3Key}
              setKeyframeLoaded={onSetIsKeyFrameLoaded}
            />
          )}
      </Box>

      <Grid
        container
        sx={{
          "& button": {
            width: "100%",
          },
        }}
        spacing="1em"
      >
        <Grid item xs={showPtButton ? 6 : 12}>
          <StyledLoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={changeViewMode}
          >
            {viewMode !== ViewModeEnum.LIVE ? "Activate" : "Stop"} live view
          </StyledLoadingButton>
        </Grid>

        {showPtButton && (
          <>
            <Grid item xs={6}>
              <PtControllerDialog
                deviceId={device.rowId}
                deviceName={device.name}
                nodeId={device.node.id}
                showPtButton
                ptOpen={ptOpen}
                onPtOpen={memoizedOpenPtControllerCallback}
                onPtClose={memoizedClosePtControllerCallback}
                hasZoom={device?.deviceData?.hasZoom}
                deviceData={device.deviceData}
                currentViewMode={viewMode}
              />
            </Grid>

            <Grid item>
              <WarningMessage showWarningIcon>
                The model will be stopped when you are running pt control
              </WarningMessage>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const LiveViewContainer = memo(LiveViewContainerComponent);

export default LiveViewContainer;
