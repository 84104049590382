import { FC, memo, useEffect } from "react";

import { Box, Paper, Typography } from "@mui/material";

import LiveBadge from "../../../../../../common/components/icons/LiveBadge";
import CircularLoading from "../../../../../../common/components/progress/CircularLoading";
import VideoNotFound from "../../../../../../common/components/stream/VideoNotFound";
import useHlsStream, {
  MAX_RETRIES,
} from "../../../../../../common/hooks/useHLSStream";
import LiveStreamPlayer from "./LiveStreamPlayer";

interface HLSStreamComponentProps {
  setDialogStyles?: boolean;
  videoLoading?: boolean;
  setVideoLoading?: (value: boolean) => void;
}

const TryingToConnect = ({
  retryCount,
}: {
  retryCount: number;
}): JSX.Element => {
  return (
    <Paper
      variant="outlined"
      square={false}
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "378px",
        backgroundColor: "#fbfbfb",
        borderColor: "#e1e6ef",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
        }}
        component="div"
        variant="inputLabel"
      >
        {/* add one to adjust for zero index */}
        Trying to connect... attempt {retryCount + 1} of {MAX_RETRIES}
        <CircularLoading />
      </Typography>
    </Paper>
  );
};

const HLSStreamComponent: FC<HLSStreamComponentProps> = ({
  setDialogStyles,
  videoLoading,
  setVideoLoading,
}): JSX.Element => {
  const { loading, hlsUrl, retryCount, needRetry, retry, setLoading } =
    useHlsStream();

  const handleRetry = (): void => {
    setLoading(true);

    retry();
  };

  useEffect(() => {
    if (setVideoLoading) {
      setVideoLoading(
        loading ||
          (!needRetry && !hlsUrl && !loading && retryCount < MAX_RETRIES)
      );
    }
  }, [loading, hlsUrl, retryCount, needRetry]);

  if (!needRetry && !hlsUrl && !loading && retryCount < MAX_RETRIES) {
    return <TryingToConnect retryCount={retryCount} />;
  }

  if (loading) {
    return <TryingToConnect retryCount={retryCount} />;
  }

  if (!needRetry && !hlsUrl && !loading && retryCount >= MAX_RETRIES) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  if (needRetry) {
    return <VideoNotFound onRetry={handleRetry} />;
  }

  return (
    <>
      {!loading && !needRetry && !videoLoading && hlsUrl && (
        <Box
          sx={{
            position: "relative",
            height: setDialogStyles ? "calc(100% - 64px)" : "auto",
          }}
        >
          <LiveBadge
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
            }}
          />
        </Box>
      )}

      <LiveStreamPlayer streamUrl={hlsUrl} setDialogStyles={setDialogStyles} />
    </>
  );
};

const HLSStreamPlayer = memo(HLSStreamComponent);

export default HLSStreamPlayer;
