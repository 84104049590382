import { useEffect, useState } from "react";

import {
  useVideoPlayerVariable,
  videoPlayerVariable,
} from "../variables/videoPlayer";
import { useStream } from "./useStream";
import { selectedStreamObjectVariable } from "../variables/selectedStreamObject";
import { usePublishNode } from "../../pages/devices/hooks/usePublishNode";
import { IDeviceTableRow } from "../../pages/devices/hooks/useDeviceTableRows";
import { extractDeviceNameFromId } from "../utils/getEntityName";

export const useStreamProcesses = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);
  const [hasPanTilt, setHasPanTilt] = useState(false);

  const { getStreamName } = useStream();
  const { publishNode } = usePublishNode();

  const isPlayerLoaded = useVideoPlayerVariable();

  const startStreaming = (device: IDeviceTableRow): void => {
    setStreamLoading(true);

    const { node, rowId: deviceId, deviceData } = device;

    const { extractedDeviceName } = extractDeviceNameFromId(deviceId);

    const { id: nodeId } = node;

    const streamName = getStreamName(nodeId, deviceId);

    // set showPanTiltButton according to device data
    setHasPanTilt(deviceData.hasPanTilt);

    if (!isPlayerLoaded) {
      publishNode({
        message: JSON.stringify({
          TARGET: "KINESIS_STREAM",
          ACTION: "KINESIS_STREAM_START",
          streamName,
          deviceName: extractedDeviceName,
        }),
        nodeId,
      }).then((): void => {
        selectedStreamObjectVariable({
          streamName,
          extractedDeviceName,
          nodeId,
        });

        setStreamLoading(false);
      });
    }
  };

  useEffect((): void => {
    setIsVideoLoaded(isPlayerLoaded);
  }, [isPlayerLoaded]);

  const stopStreaming = (device: IDeviceTableRow): void => {
    const { node, rowId: deviceId } = device;

    const { extractedDeviceName } = extractDeviceNameFromId(deviceId);

    const { id: nodeId } = node;

    const streamName = getStreamName(nodeId, deviceId);

    if (streamName) {
      publishNode({
        message: JSON.stringify({
          TARGET: "KINESIS_STREAM",
          ACTION: "KINESIS_STREAM_END",
          streamName,
          deviceName: extractedDeviceName,
        }),
        nodeId,
      });

      selectedStreamObjectVariable(null);

      videoPlayerVariable(false);
    }
  };

  return {
    isVideoLoaded,
    hasPanTilt,
    streamLoading,
    startStreaming,
    stopStreaming,
  };
};
