import { useEffect, useState } from "react";

import {
  selectedZoneVariable,
  useSelectedZoneVariable,
} from "../../pages/data-hub/components/liveview/components/devices/ptzonelist/zones.variable";
import { useListenToDeviceDataChange } from "../../pages/deployment-health/hooks/useListenToDeviceDataChange";
import { DeviceDataType } from "../../pages/devices/hooks/useDeviceTableRows";
import { usePublishNode } from "../../pages/devices/hooks/usePublishNode";
import { useZoneFormValidation } from "../../pages/devices/zones-setup-page/hooks/useZoneFormValidation";
import { extractDeviceNameFromId } from "../utils/getEntityName";
import { selectedKeyframeVariable } from "../variables/selectedKeyframe";

export const PAN_MIN = -180;

export const PAN_MAX = 180;

export const TILT_MIN = -90;

export const TILT_MAX = 35;

export const ZOOM_MIN = 0;

export const ZOOM_MAX = 100;

export const PAN_TILT_STEP = 1;

export const PAN_DEFAULT = "0";

export const TILT_DEFAULT = "0";

export const ZOOM_DEFAULT = "0";

export type GoToPTHandlerType = (
  pan: string,
  tilt: string,
  zoom: string,
  selectedZoneName?: string
) => void;

interface IUsePTController {
  deviceId: string;
  deviceName: string;
  nodeId: string;
  deviceData?: DeviceDataType;
  zoneName?: string;
}

export const usePTController = ({
  deviceId,
  deviceName,
  nodeId,
  deviceData,
  zoneName,
}: IUsePTController) => {
  const selectedZone = useSelectedZoneVariable();

  const {
    validatePanTilt,
    validationState,
    setValidationState,
    validateZoom,
    resetValidationState,
    validateCreateZoneForm,
  } = useZoneFormValidation(zoneName ?? selectedZone?.zoneName);

  const { publishNode } = usePublishNode();

  const { extractedDeviceName } = extractDeviceNameFromId(deviceId);

  const { data, deviceData: subscribedDeviceData } =
    useListenToDeviceDataChange({ currentDeviceId: deviceId });

  const [pan, setPan] = useState(PAN_DEFAULT);
  const [tilt, setTilt] = useState(TILT_DEFAULT);
  const [zoom, setZoom] = useState(ZOOM_DEFAULT);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (deviceData) {
      const panValue = deviceData?.pan ? `${deviceData.pan}` : PAN_DEFAULT;

      const tiltValue = deviceData?.tilt ? `${deviceData.tilt}` : TILT_DEFAULT;

      const zoomValue = deviceData?.zoom ? `${deviceData.zoom}` : ZOOM_DEFAULT;

      setPan(panValue);

      setTilt(tiltValue);

      setZoom(zoomValue);

      selectedZoneVariable({
        zoneName: "",
        pan: panValue,
        tilt: tiltValue,
        zoom: zoomValue,
      });
    }
  }, [deviceData]);

  useEffect(() => {
    if (subscribedDeviceData && data?.listenToDeviceData?.name === deviceName) {
      const panValue = subscribedDeviceData.pan
        ? `${subscribedDeviceData.pan}`
        : PAN_DEFAULT;

      const tiltValue = subscribedDeviceData.tilt
        ? `${subscribedDeviceData.tilt}`
        : TILT_DEFAULT;

      const zoomValue = subscribedDeviceData.zoom
        ? `${subscribedDeviceData.zoom}`
        : ZOOM_DEFAULT;

      setPan(panValue);

      setTilt(tiltValue);

      setZoom(zoomValue);

      selectedZoneVariable({
        zoneName: "",
        pan: panValue,
        tilt: tiltValue,
        zoom: zoomValue,
      });
    }
  }, [subscribedDeviceData]);

  useEffect(() => {
    if (selectedZone && selectedZone.zoneName) {
      if (selectedZone.pan !== pan) {
        setPan(selectedZone.pan);
      }

      if (selectedZone.tilt !== tilt) {
        setTilt(selectedZone.tilt);
      }

      if (selectedZone.zoom !== zoom) {
        setZoom(selectedZone.zoom);
      }
    }
  }, [selectedZone.pan, selectedZone.tilt, selectedZone.zoom]);

  const disablePtController = (): void => {
    setDisabled(true);

    setTimeout((): void => {
      setDisabled(false);
    }, 100);
  };

  const movePtUp = (): void => {
    selectedKeyframeVariable("");

    adjustByStep.setVerticalUp();
  };

  const movePtDown = (): void => {
    selectedKeyframeVariable("");

    adjustByStep.setVerticalDown();
  };

  const movePtLeft = (): void => {
    selectedKeyframeVariable("");

    adjustByStep.setHorizontalDown();
  };

  const movePtRight = (): void => {
    selectedKeyframeVariable("");

    adjustByStep.setHorizontalUp();
  };

  const handleGoToPt: GoToPTHandlerType = (
    panPosition,
    tiltPosition,
    zoomValue,
    selectedZoneName = ""
  ): void => {
    selectedKeyframeVariable("");

    const isValid = validatePanTilt(panPosition, tiltPosition);

    const hasZoom = deviceData?.hasZoom;

    const isValidZoom = hasZoom ? validateZoom(zoomValue) : true;

    if (isValid && isValidZoom) {
      setPan(panPosition);

      setTilt(tiltPosition);

      setZoom(zoomValue);

      selectedZoneVariable({
        zoneName: selectedZoneName,
        pan: panPosition,
        tilt: tiltPosition,
        zoom: zoomValue,
      });

      disablePtController();

      publishNode({
        message: JSON.stringify({
          TARGET: "PANTILT",
          ACTION: "GOTO_PT",
          device_name: extractedDeviceName,
          pan: panPosition,
          tilt: tiltPosition,
          zoom: zoomValue,
        }),
        nodeId,
      });
    }
  };

  const handlePanInputChange = (pan = ""): void => {
    selectedKeyframeVariable("");

    setValidationState(prev => ({
      ...prev,
      pan: {
        hasError: false,
        errorMessage: "",
      },
      panValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    setPan(pan);
  };

  const handleTiltInputChange = (tilt = ""): void => {
    selectedKeyframeVariable("");

    setValidationState(prev => ({
      ...prev,
      tilt: {
        hasError: false,
        errorMessage: "",
      },
      tiltValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    setTilt(tilt);
  };

  const handleZoomInputChange = (zoom = ""): void => {
    selectedKeyframeVariable("");

    setValidationState(prev => ({
      ...prev,
      zoom: {
        hasError: false,
        errorMessage: "",
      },
      zoomValue: {
        hasError: false,
        errorMessage: "",
      },
    }));

    setZoom(zoom);
  };

  const adjustByStep = {
    setHorizontalUp(): void {
      let newPan = `${+pan + PAN_TILT_STEP}`;

      // MOVE_PT_RIGHT
      if (+pan >= PAN_MAX) {
        newPan = `${PAN_MAX}`;
      }

      if (+newPan === +pan) return;

      handleGoToPt(newPan, tilt, zoom);
    },

    setHorizontalDown(): void {
      let newPan = `${+pan - PAN_TILT_STEP}`;

      // MOVE_PT_LEFT
      if (+pan <= PAN_MIN) {
        newPan = `${PAN_MIN}`;
      }

      if (+newPan === +pan) return;

      handleGoToPt(newPan, tilt, zoom);
    },

    setVerticalUp(): void {
      let newTilt = `${+tilt + PAN_TILT_STEP}`;

      // MOVE_PT_UP
      if (+newTilt >= TILT_MAX) {
        newTilt = `${TILT_MAX}`;
      }

      if (+newTilt === +tilt) return;

      handleGoToPt(pan, newTilt, zoom);
    },

    setVerticalDown(): void {
      let newTilt = `${+tilt - PAN_TILT_STEP}`;

      if (+tilt <= TILT_MIN) {
        newTilt = `${TILT_MIN}`;
      }

      if (+newTilt === +tilt) return;

      handleGoToPt(pan, newTilt, zoom);
    },
  };

  return {
    pan,
    tilt,
    zoom,
    disabled,
    adjustByStep,
    movePtUp,
    movePtDown,
    movePtLeft,
    movePtRight,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    validationState,
    setValidationState,
    resetValidationState,
    validateCreateZoneForm,
  };
};
