import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import {
  GetDeviceByIdQuery,
  GetDeviceByIdQueryVariables,
  Service,
} from "../../../API";
import { useGetServicesByCustomer } from "../../../common/components/select/ServiceSelect/useGetServicesByCustomer";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import useLocationFromCache from "../../../common/hooks/useLocationFromCache";
import { GET_DEVICE_BY_ID } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import {
  deviceDataVariable,
  deviceFormVariables,
  useDeviceDataState,
} from "../variables/devices";
import { IDeviceTableRow, IModel } from "./useDeviceTableRows";
import useGetDeviceMakeByModel from "./useGetDeviceMakeByModel";
import {
  IGasLeakModel,
  ILiquidLeakModel,
  IFireSmokeModel,
  ITankLevelModel,
  IGateGuardModel,
  IHardHatModel,
  ServiceTypeEnum,
} from "../../model-manager/variables/modelManager";
import { useListenToDeviceHealthDataChange } from "../../deployment-health/hooks/useListenToDeviceHealthDataChange";
import { isNetworkHealthOnline } from "../../../common/helpers/isNetworkHealthOnline";
import { useListenToNodeOnlineStatusChange } from "../../deployment-health/hooks/useListenToNodeHealthDataChange";

export interface IJsonData {
  [key: string]:
    | IGasLeakModel
    | ILiquidLeakModel
    | IFireSmokeModel
    | IHardHatModel
    | ITankLevelModel
    | IGateGuardModel
    | string
    | boolean;
}

const useGetDeviceByUrl = () => {
  const { deviceId = "" } = useParams();

  const customerId = useCustomerIdGuard();
  const { getCachedLocation } = useLocationFromCache();

  const { fetchDeviceMakeByModel } = useGetDeviceMakeByModel();

  const deviceVariable = useDeviceDataState();

  const { data: serviceData, loading: getServicesLoading } =
    useGetServicesByCustomer();

  const { data: deviceHealthData, isOnline } =
    useListenToDeviceHealthDataChange();

  const { data: nodeData } = useListenToNodeOnlineStatusChange();

  const [getDevice, { data, loading: getDeviceLoading }] = useLazyQuery<
    GetDeviceByIdQuery,
    GetDeviceByIdQueryVariables
  >(GET_DEVICE_BY_ID, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = () => {
    client.cache
      .reset()
      .then(() => {
        errorNotification();
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (
      nodeData?.listenToNodeOnlineStatus?.nodeId?.replaceAll("_", "#") ===
        data?.getDeviceById?.nodeId &&
      nodeData?.listenToNodeOnlineStatus?.isOnline === false &&
      deviceVariable
    ) {
      deviceDataVariable({ ...deviceVariable, isOnline: false });
    }
  }, [nodeData]);

  useEffect(() => {
    const isDeviceDataFromSubscription =
      deviceHealthData?.listenToDeviceHealthData;

    if (!isDeviceDataFromSubscription || !deviceVariable) return;

    if (isDeviceDataFromSubscription.id === deviceVariable.rowId) {
      deviceDataVariable({ ...deviceVariable, isOnline: isOnline });
    }
  }, [deviceHealthData]);

  const fetchDevice = () => {
    return getDevice({
      variables: {
        customerId,
        deviceId,
      },
    })
      .then(response => {
        const device = response?.data?.getDeviceById;

        if (device) {
          deviceFormVariables({
            isDirty: false,
            cameraIpAddress: device.cameraIpAddress ?? "",
            deviceData: device.deviceData,
            deviceName: device.name,
            deviceType: device.makeModelId,
            location: {
              value: device.locationId ?? "",
              title: device.locationName ?? "",
            },
            node: {
              id: device.nodeId ?? "",
              name: device.nodeName ?? "",
            },
            id: device.id,
          });
        }

        if (response.error) {
          handleRequestError();
        }

        // const item = response?.data?.getDeviceById;
        const locationCache = getCachedLocation(device?.locationId as string);

        const allModels = serviceData?.getServices?.items as Service[];
        const models = allModels?.filter(service => {
          // note: item is device i.e. item.id will always be DE#<>
          // legacy service item's deviceId field has no C# prefix e.g. DE#<>
          const doesLegacyIdsMatch = service?.deviceId === device?.id;
          // service item's deviceId field has C# prefix e.g. C#<>DE#<>; must remove before comparison
          const doesIdsMatch =
            `DE#${service?.deviceId?.split("DE#")[1]}` === device?.id;

          return doesLegacyIdsMatch || doesIdsMatch;
        });

        return fetchDeviceMakeByModel(device?.makeModelId ?? "").then(
          deviceMakeResponse => {
            if (!device) return;

            const result: IDeviceTableRow = {
              rowId: device.id as string,
              name: device.name as string,
              healthData: device.healthData,
              location: {
                id: device.locationId as string,
                name: locationCache?.name ?? "",
              },
              node: {
                id: device.nodeId as string,
                name: device.nodeName as string,
              },
              makeModelId: device?.makeModelId as string,
              models:
                models?.map(
                  (service): IModel => ({
                    serviceName:
                      (service?.serviceType as ServiceTypeEnum) ?? "",
                    serviceId: service?.id ?? "",
                    nodeId: service?.nodeId ?? "",
                    isRunning: service?.isRunning ?? false,
                    configuration: service?.configuration ?? "",
                  })
                ) ?? [],

              modelsToRun:
                (deviceMakeResponse?.data?.getDeviceMakeByModel
                  ?.modelsToRun as string[]) ?? [],
              rtspTemplate:
                deviceMakeResponse?.data?.getDeviceMakeByModel.rtspTemplate ??
                "",
              deviceData: device.deviceData,
              isOnline: isNetworkHealthOnline(
                device?.healthData.lastAlive ? +device.healthData.lastAlive : 0
              ),
              cameraIpAddress: device?.cameraIpAddress as string,
            };

            const deviceMakePanTiltValue =
              deviceMakeResponse?.data?.getDeviceMakeByModel.hasPanTilt;

            const deviceMakeHasZoomValue =
              deviceMakeResponse?.data?.getDeviceMakeByModel.hasZoom ?? false;

            deviceFormVariables({
              isDirty: false,
              cameraIpAddress: device?.cameraIpAddress ?? "",
              deviceData: device.deviceData,
              deviceName: device.name ?? "",
              deviceType: device.makeModelId ?? "",
              location: {
                value: device.locationId ?? "",
                title: device.locationName ?? "",
              },
              node: {
                id: device.nodeId ?? "",
                name: device.nodeName ?? "",
              },
              rtspTemplate:
                deviceMakeResponse?.data?.getDeviceMakeByModel.rtspTemplate ??
                "",
              defaultUserPwd:
                deviceMakeResponse?.data?.getDeviceMakeByModel.defaultUserPwd ??
                "",
              deviceMakePanTiltValue,
              deviceMakeHasZoomValue,
              id: device.id,
            });

            return result;
          }
        );
      })
      .catch(e => {
        console.error(e);

        handleRequestError();
      });
  };

  return {
    data,
    fetchDevice,
    loading: getDeviceLoading,
    getServicesLoading,
    isServiceLoaded: !!serviceData,
  };
};

export default useGetDeviceByUrl;
