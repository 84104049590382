import { useNavigate } from "react-router-dom";

import { useConfirm } from "material-ui-confirm";

import { Box, Typography, styled, CSSObject } from "@mui/material";

import { useSetIsRunningServiceStatus } from "./useSetIsRunningServiceStatus";
import confirmDialogStyleOptions from "../../../common/helpers/confirmDialogParams";
import { useDeleteService } from "../../model-manager/hooks/useDeleteService";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { IDeviceTableRow, IModel } from "./useDeviceTableRows";
import { useGetZonesByDevice } from "../../data-hub/components/liveview/components/zonelist/hooks/useGetZonesByDevice";
import CircularLoading from "../../../common/components/progress/CircularLoading";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useSyncShadow } from "../../model-manager/hooks/useSyncShadow";
import { deviceDataVariable } from "../variables/devices";

const CustomBoxComponentStyled = styled(Box)(
  ({ theme }): CSSObject => ({
    maxWidth: "100%",
    borderRadius: "10px",
    width: "100%",
    height: "80px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: theme.palette.otherBackground.main,
    border: `1px solid ${theme.palette.otherOutlineBorder.main}`,
    "& .TableCell-color": {
      color: theme.typography.inputLabel.color,
    },
  })
);

const ModelConfirm = ({
  deviceText,
  modelText,
}: {
  deviceText: string;
  modelText: string;
}): JSX.Element => {
  return (
    <CustomBoxComponentStyled>
      <Box display="flex">
        <Box
          flex={1}
          className="TableCell-color"
          sx={{ textAlign: "left" }}
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle2">DEVICE</Typography>
        </Box>
        <Box flex={2} sx={{ textAlign: "left" }}>
          <Typography variant="body2Regular">{deviceText}</Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box
          flex={1}
          className="TableCell-color"
          sx={{ textAlign: "left" }}
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle2">MODEL</Typography>
        </Box>
        <Box flex={2} sx={{ textAlign: "left" }}>
          <Typography variant="body2Regular">{modelText}</Typography>
        </Box>
      </Box>
    </CustomBoxComponentStyled>
  );
};

interface ServiceActionsParams {
  deviceModel: IModel;
  device: IDeviceTableRow;
}

type ServiceActionReturnType = {
  changeModelStatusAction: (runStatus: boolean) => Promise<void>;

  deleteModelAction: (redirectLink?: string) => Promise<void | string | null>;

  serviceLoading: {
    updateStatusLoading: boolean;
    deleteModelLoading: boolean;
    commonLoading: boolean;
  };
};

const ConfirmDeletingContent = ({
  deviceModel,
  device,
}: ServiceActionsParams): JSX.Element => {
  const { data: zonesData, loading } = useGetZonesByDevice();

  const hasAssignedZones = !!zonesData?.getZonesByDevice.items.length;

  const content = hasAssignedZones ? (
    <>
      If you delete the {deviceModel.serviceName} model{" "}
      <span style={{ color: "red" }}> with all zones</span>, this data will be
      deleted forever and you won’t be able to restore it.
    </>
  ) : (
    "All data will be deleted and can’t be restored"
  );

  const title = (
    <>
      Are you sure you want to delete the {deviceModel?.serviceName} model for
      {` ${device.name} ${hasAssignedZones ? " with all zones?" : "?"}`}
    </>
  );

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ marginBottom: "0.5em" }} variant="h4">
        {title}
      </Typography>

      <Typography>{content}</Typography>
    </Box>
  );
};

export const useServiceActions = ({
  deviceModel,
  device,
}: ServiceActionsParams): ServiceActionReturnType => {
  const customerId = useCustomerIdGuard();

  const { setIsRunningServiceStatus, loading: updateStatusLoading } =
    useSetIsRunningServiceStatus();
  const { deleteService, loading: deleteModelLoading } = useDeleteService();

  const confirm = useConfirm();
  const navigate = useNavigate();

  const { syncShadowsForNode } = useSyncShadow();

  const serviceId = deviceModel?.serviceId ?? "";
  const nodeId = deviceModel?.nodeId ?? "";

  const changeModelStatusAction: (isModelRunning: boolean) => Promise<void> = (
    isModelRunning: boolean
  ): Promise<void> => {
    const inputParams = {
      nodeId,
      serviceId,
      runStatus: !isModelRunning,
    };

    const { title, confirmationText } = {
      title: isModelRunning
        ? "If you disable the model, it won’t be running"
        : "If you enable the model, it will be running again",
      confirmationText: isModelRunning
        ? "Disable the model"
        : "Enable the model",
    };

    const notificationMessage = isModelRunning
      ? `You disabled ${deviceModel?.serviceName} model for ${device.name}`
      : `You enabled ${deviceModel?.serviceName} model for ${device.name}`;

    return confirm({
      title,
      confirmationText,
      content: (
        <Box
          sx={{
            borderRadius: "8px",
            background:
              "linear-gradient(0deg, #F7F8FA, #F7F8FA), linear-gradient(0deg, #E1E6EF, #E1E6EF)",
          }}
        >
          <ModelConfirm
            deviceText={device.name}
            modelText={deviceModel.serviceName}
          />
        </Box>
      ),
      cancellationText: "Cancel",
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
        color: "primary",
      },
    }).then(() =>
      setIsRunningServiceStatus(inputParams).then(() => {
        deviceDataVariable({
          ...device,
          models: device.models.map(model => {
            if (model.serviceId !== serviceId) {
              return model;
            }

            return {
              ...model,
              isRunning: !isModelRunning,
            };
          }),
        });

        successNotification(notificationMessage);
      })
    );
  };

  const deleteModelAction = async (
    redirectLink?: string
  ): Promise<void | string | null> => {
    const removeServiceFromDDB = async (): Promise<string | null> => {
      const deletedModel = await deleteService({
        nodeId,
        serviceId,
        // temporary fix before we make the change of adding C# to deviceId upon device creation
        deviceId: customerId + "#" + device.rowId,
      });

      syncShadowsForNode(deviceModel.nodeId);

      if (redirectLink) {
        navigate(redirectLink);
      }

      return deletedModel.data?.deleteService?.id ?? null;
    };

    return confirm({
      title: "",
      content: (
        <ConfirmDeletingContent device={device} deviceModel={deviceModel} />
      ),
      cancellationText: "Cancel",
      confirmationText: "Delete",
      // confirmationKeyword: "delete",
      // confirmationKeywordTextFieldProps: {
      //   placeholder: "Type “delete” word here",
      //   sx: {
      //     id: "deleting_confirm_id",
      //     padding: "0 2em",
      //     "& .MuiInputBase-root": {
      //       marginTop: "2em",
      //       borderRadius: "8px",
      //     },
      //   },
      // },
      ...confirmDialogStyleOptions,
      confirmationButtonProps: {
        ...confirmDialogStyleOptions.confirmationButtonProps,
      },
    })
      .then(removeServiceFromDDB)
      .catch((error): void => {
        if (error) {
          errorNotification("Something went wrong when deleting model");

          console.error(error);
        }
      });
  };

  return {
    changeModelStatusAction,
    deleteModelAction,
    serviceLoading: {
      updateStatusLoading,
      deleteModelLoading,
      commonLoading: updateStatusLoading || deleteModelLoading,
    },
  };
};
