import { MouseEvent, memo, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { CSSObject } from "@mui/system";
import PopupState from "material-ui-popup-state";
import { type PopupState as PopupStateType } from "material-ui-popup-state/hooks";

import {
  IDeviceTableRow,
  IModel,
} from "../../../pages/devices/hooks/useDeviceTableRows";
import { useServiceActions } from "../../../pages/devices/hooks/useServiceActions";
import { useSyncShadow } from "../../../pages/model-manager/hooks/useSyncShadow";
import { ServiceTypeEnum } from "../../../pages/model-manager/variables/modelManager";
import ChipComponent from "./ChipComponent";
import StyledModelMenuComponent from "./StyledModelMenuComponent";

export const StyledArrowLessIcon = styled(ExpandLessRoundedIcon)(
  ({ theme }): CSSObject => ({
    color: theme.palette.primary.main,
    cursor: "pointer",
  })
);

export const StyledArrowMoreIcon = styled(ExpandMoreRoundedIcon)(
  ({ theme }): CSSObject => ({
    cursor: "pointer",
    color: theme.palette.otherStandardInputLine.main,
    "&:hover": {
      borderRadius: "10px",
      backgroundColor: theme.palette.otherOutlineBorder.main,
      color: theme.palette.text.secondary,
    },
  })
);

interface IChipDropdownProps {
  model: IModel;
  data: IDeviceTableRow;
  runningLeakModels?: string[];
  onDeleteModelHandler: (modelId: string) => void;
}

const DeviceModelChipDropdown = ({
  model,
  onDeleteModelHandler,
  data,
  runningLeakModels,
}: IChipDropdownProps): JSX.Element => {
  const { syncShadowsForNode } = useSyncShadow();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModelRunning, setIsModelRunning] = useState(!!model.isRunning);

  if (model.serviceName === ServiceTypeEnum.GAS_LEAK) {
    model.serviceName = ServiceTypeEnum.LEAK;
  }

  const link = `/device/${encodeURIComponent(
    data.rowId
  )}/model/${encodeURIComponent(model.serviceName)}`;
  const open = Boolean(anchorEl);
  const location = useLocation();

  location.state = { deviceId: data?.rowId };

  const onChangeModelStatusHandler = (): void => {
    changeModelStatusAction(isModelRunning).then((): void => {
      setIsModelRunning(!isModelRunning);

      syncShadowsForNode(data.node?.id);
    });
  };

  const { changeModelStatusAction, deleteModelAction, serviceLoading } =
    useServiceActions({
      deviceModel: model,
      device: data,
    });

  const { updateStatusLoading } = serviceLoading;

  const onDeleteModel = async (): Promise<void> => {
    const deletedModelId = await deleteModelAction();

    if (!deletedModelId) return;

    onDeleteModelHandler(deletedModelId);

    syncShadowsForNode(data.node?.id);
  };

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState: PopupStateType): JSX.Element => {
        const memoizePopupState = useMemo((): PopupStateType => popupState, []);

        return (
          <Box sx={{ position: "relative" }}>
            <ChipComponent
              activeState={isModelRunning}
              label={model.serviceName}
              routeLink={link}
              routeData={data}
            />

            <Box
              className="arrow"
              onClick={handleClick}
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {updateStatusLoading && (
                <CircularProgress sx={{ marginRight: "5px" }} size={14} />
              )}

              {open && !updateStatusLoading ? (
                <StyledArrowLessIcon />
              ) : (
                !updateStatusLoading && <StyledArrowMoreIcon />
              )}
            </Box>

            {open && (
              <StyledModelMenuComponent
                currentModel={model}
                onDeleteHandler={onDeleteModel}
                onChangeModelStatus={onChangeModelStatusHandler}
                anchorEl={anchorEl}
                isRunning={isModelRunning}
                handleClose={handleClose}
                popupState={memoizePopupState}
                runningLeakModels={runningLeakModels}
              />
            )}
          </Box>
        );
      }}
    </PopupState>
  );
};

export default memo(DeviceModelChipDropdown);
