import { VideoMethod } from "../../../API";
import { customRound } from "../../../common/helpers/customRound";
import { useBase64 } from "../../../common/hooks/useBase64";
import useUserSettingsFromCache from "../../../common/hooks/useUserSettingsFromCache";
import { S3BucketKeys } from "../../../common/utils/s3helper";
import { UnitsEnum } from "../../pages/UploadVideoGroupPage";
import useConvertArrayToCSV from "../useConvertArrayToCSV";
import useCSVDownloader from "../useCSVDownloader";
import { LeakFinderTableRow } from "../useGetVideosTableRows";

const useExportGroupData = () => {
  const { getBase64MediaViewerPageLinkFromS3Key } = useBase64();

  const { downloadCSV } = useCSVDownloader();

  const { convertArrayToCSV } = useConvertArrayToCSV();

  const { userSettings } = useUserSettingsFromCache();

  const aggColumns = [
    { field: "videoName", headerName: "VIDEO" },
    { field: "geometry", headerName: "ANGLE / HEIGHT / DISTANCE" },
    { field: "fov", headerName: "FOV" },
    { field: "windspeed", headerName: "WINDSPEED" },
    { field: "outputMeasurement", headerName: "RESULT" },
    { field: "videoMethod", headerName: "VIDEO TYPE" },
    { field: "notes", headerName: "NOTES" },
    { field: "modelOutputS3Key", headerName: "MEDIA" },
  ];

  const exportUploadsData = (rows: LeakFinderTableRow[]) => {
    if (rows.length > 0) {
      const updatedRows = rows.map(row => {
        const geometry = row.geometry && JSON.parse(row.geometry);

        const unit =
          userSettings?.defaultWindspeedUnit === UnitsEnum.MPH
            ? UnitsEnum.MPH
            : UnitsEnum.KPH;

        const mphToKph = 1.609344;

        const windspeed =
          unit === UnitsEnum.KPH ? row.windspeed * mphToKph : row.windspeed;

        const convertOutputMeasurement = row.outputMeasurement
          ? row.outputMeasurement * 86400
          : null;

        const measurement = convertOutputMeasurement
          ? customRound(convertOutputMeasurement)
          : null;

        const videoMethodText =
          row.videoMethod === VideoMethod.DRONE ? "Drone" : "Handheld";

        return {
          ...row,
          videoName: row.videoName,
          geometry: JSON.stringify(geometry),
          fov: row.fov,
          windspeed,
          outputMeasurement: measurement,
          videoMethod: videoMethodText,
          notes: row.notes,
          modelOutputS3Key: getBase64MediaViewerPageLinkFromS3Key(
            row.modelOutputS3Key ?? "",
            S3BucketKeys.LEAK_FINDER
          ),
        };
      });

      const csvString = convertArrayToCSV(updatedRows, aggColumns);

      downloadCSV(csvString, `client-uploads-events.csv`);
    }
  };

  return { exportUploadsData };
};

export default useExportGroupData;
