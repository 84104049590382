import { Auth } from "aws-amplify";
import AWS, { AWSError } from "aws-sdk";
import dayjs from "dayjs";

import aws from "../../aws-exports.json";
import { getJwtToken } from "./getJwtToken";

export type S3BucketKeyType = "LeakFinder" | "NodeFiles" | "UserFiles";

export const S3BucketKeys = {
  LEAK_FINDER: "LeakFinder",
  NODE_FILES: "NodeFiles",
  USER_FILES: "UserFiles",
};

export const S3Buckets = {
  LeakFinder: aws.aws_leak_finder_s3_bucket,
  NodeFiles: aws.aws_node_files_s3_bucket,
  UserFiles: aws.aws_user_files_s3_bucket,
};

export class S3Helper {
  public static initialized = false;

  private static s3: AWS.S3;
  private static credentials: AWS.CognitoIdentityCredentials;

  public static async setAWSConfig() {
    if (!this.s3 || !this.s3.config.credentials?.sessionToken) {
      const jwt = await getJwtToken();

      if (!jwt) {
        this.initialized = false;

        return;
      }

      AWS.config.region = aws.aws_user_files_s3_bucket_region;

      this.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: aws.aws_cognito_identity_pool_id,
        Logins: {
          ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]: jwt,
        },
      });

      AWS.config.credentials = this.credentials;

      await this.credentials.getPromise();

      this.s3 = new AWS.S3();

      this.initialized = !!this.s3;

      this.scheduleNextCredentialRefresh();
    }
  }

  public static async checkIfFileExists(signedUrl: string) {
    try {
      const response = await fetch(signedUrl, {
        headers: {
          // since the signedUrl only allows GET but we want to check for existing file, we limit the byte range so that we can still check if the file exists without getting the whole request
          Range: `bytes=${200}-${206}`,
        },
        method: "GET",
      });

      if (response.ok && response.status === 206) {
        return true;
      } else {
        // URL is invalid, consider an error
        return false;
      }
    } catch (err) {
      // fetch failed, consider an error
      return false;
    }
  }

  public static async getObject(objectKey: string) {
    if (!this.s3) {
      await this.setAWSConfig();
    }

    const needsRefresh = this.credentials.needsRefresh();

    if (needsRefresh) {
      await this.refreshCredentials();
    }

    const key =
      objectKey.charAt(0) === "/" ? objectKey.substring(1) : objectKey;

    try {
      const params = {
        Bucket: S3Buckets.NodeFiles,
        Key: key,
        ResponseCacheControl: "no-cache",
      };

      const data = await this.s3.getObject(params).promise();

      const blob = new Blob([data.Body as Blob], { type: data.ContentType });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      this.initialized = false;

      console.error(
        `Could not retrieve file from S3: ${(error as AWSError).message}`
      );

      return "";
    }
  }

  public static async getSignedUrl(
    objectKey: string,
    operation: "getObject" | "putObject" | "deleteObject",
    contentType?: string
  ) {
    if (!this.s3) {
      await this.setAWSConfig();
    }

    const needsRefresh = this.credentials.needsRefresh();

    if (needsRefresh) {
      await this.refreshCredentials();
    }

    const params = {
      Bucket: S3Buckets.LeakFinder,
      Key: objectKey,
      Expires: 3600,
      ContentType: contentType,
    };

    return new Promise<string>((resolve, reject) => {
      this.s3.getSignedUrl(operation, params, (err, url) => {
        if (err) {
          console.error(`Could not generate signed URL: ${err.message}`);

          this.initialized = false;

          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }

  public static async deleteFolderFromS3(
    folderPath: string,
    bucketName = S3Buckets.LeakFinder
  ) {
    if (!this.s3) {
      await this.setAWSConfig();
    }

    const actualFolderPath = folderPath.replaceAll("#", "_");

    const listParams = {
      Bucket: bucketName,
      Prefix: actualFolderPath,
    };

    const listObjects = await this.s3.listObjectsV2(listParams).promise();
    const objectsToDelete =
      listObjects.Contents?.map(item => ({ Key: item.Key! })) || [];

    if (objectsToDelete.length === 0) {
      console.log("No objects found in the folder.");

      return;
    }

    const deleteParams = {
      Bucket: bucketName,
      Delete: { Objects: objectsToDelete },
    };

    await this.s3.deleteObjects(deleteParams).promise();

    console.log("Folder and its contents have been deleted.");
  }

  private static async refreshCredentials() {
    if (this.credentials) {
      const cognitoUser = await Auth.currentUserPoolUser({
        bypassCache: true,
      });

      const currentSession = await Auth.currentSession();

      const refreshToken = currentSession.getRefreshToken();

      cognitoUser.refreshSession(refreshToken, async (err: Error) => {
        if (err) {
          this.initialized = false;

          console.error("refreshSession ERROR", err);
        } else {
          AWS.config.region = aws.aws_user_files_s3_bucket_region;

          this.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: aws.aws_cognito_identity_pool_id,
            Logins: {
              ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]:
                currentSession.getIdToken().getJwtToken(),
            },
          });

          AWS.config.credentials = this.credentials;

          await this.credentials.getPromise();

          this.s3 = new AWS.S3();

          this.initialized = !!this.s3;

          this.scheduleNextCredentialRefresh();
        }
      });
    }
  }

  private static scheduleNextCredentialRefresh(minutesBeforeExpiration = 5) {
    if (this.credentials.expireTime) {
      const refreshTime =
        dayjs(this.credentials.expireTime).diff(dayjs(), "millisecond") -
        60000 * minutesBeforeExpiration; // default 5 minutes before expiration

      setTimeout(() => this.refreshCredentials(), refreshTime);
    }
  }
}
