import { FC, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import CircularLoading from "../../../common/components/progress/CircularLoading";
import { S3BucketKeys, S3BucketKeyType } from "../../../common/utils/s3helper";
import CardMediaVideoPlayerWithSpeedControls from "./player/CardMediaVideoPlayerWithSpeedControls";
import { useGetSignedUrlAction } from "../../../leak-finder/hooks/useGetSignedUrlAction";
import NoMedia from "./errors/NoMedia";
import { signedUrlActions } from "../../../API";

interface IVideoViewerProps {
  s3Key: string;
  s3BucketKey: S3BucketKeyType;
}

const VideoViewer: FC<IVideoViewerProps> = ({ s3Key, s3BucketKey }) => {
  const [mediaUrl, setMediaUrl] = useState("");

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);

  const { fetchSignedUrlAction } = useGetSignedUrlAction();

  useEffect((): void => {
    getVideo();
  }, [s3Key]);

  const getVideo = async (): Promise<void> => {
    if (!s3Key) {
      setMediaUrl("");

      return;
    }

    const isLeakFinderUrl = S3BucketKeys.LEAK_FINDER === s3BucketKey;

    const bucketKey = isLeakFinderUrl
      ? S3BucketKeys.LEAK_FINDER
      : S3BucketKeys.NODE_FILES;

    const signedUrl = await fetchSignedUrlAction({
      objectKey: s3Key.replace(/^\/+/, ""), // remove leading slash if it exists,
      operation: signedUrlActions.getObject,
      bucketKey,
    });

    setLoading(false);

    if (!signedUrl) {
      setError(true);

      setMediaUrl("");

      return;
    }

    setMediaUrl(signedUrl);
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularLoading />
      </Box>
    );
  }

  if (error) {
    return <NoMedia />;
  }

  return (
    <>
      {!loading && mediaUrl && (
        <CardMediaVideoPlayerWithSpeedControls
          sx={{
            maxHeight: "100vh",
          }}
          controls
          src={mediaUrl}
          autoPlay
          onError={() => setError(true)}
        />
      )}
    </>
  );
};

export default VideoViewer;
