import { useEffect, useState } from "react";

import { useSelectedStreamObject } from "../variables/selectedStreamObject";
import { useGetHlsStream } from "./useGetHlsStream";

export const MAX_RETRIES = 2; // try to automatically reconnect twice before asking for a manual retry

const useHlsStream = () => {
  const [loading, setLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const selectedStreamObject = useSelectedStreamObject();

  const stream = selectedStreamObject ? selectedStreamObject.streamName : "";

  const { hlsUrl, refetch } = useGetHlsStream(stream);

  const fetchHlsUrl = async () => {
    try {
      if (retryCount < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, 3000));

        await refetch();

        setRetryCount(prevCount => prevCount + 1);
      }
    } catch (error) {
      console.error("Error while fetching HLS URL", error);

      setRetryCount(prevCount => prevCount + 1);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (stream && !hlsUrl && retryCount < MAX_RETRIES) {
      setLoading(true);

      fetchHlsUrl();
    }

    if (retryCount >= MAX_RETRIES) {
      setLoading(false);
    }

    if (hlsUrl) {
      setRetryCount(0);

      setLoading(false);
    }
  }, [stream, hlsUrl, retryCount]);

  useEffect((): (() => void) => {
    return (): void => {
      setLoading(false);

      setRetryCount(0);
    };
  }, []);

  const retry = () => {
    // set retrCount to 0 to trigger useEffect
    setRetryCount(0);
  };

  return {
    loading,
    hlsUrl,
    retryCount,
    needRetry: retryCount >= MAX_RETRIES,
    retry,
    setLoading,
  };
};

export default useHlsStream;
